import { motion } from "framer-motion";
import { Link } from "react-router-dom";

function NotFound() {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        duration: 0.5,
      }}
      className="full-screen flex flex-col items-center justify-center"
    >
      <h1 className="font-semibold text-2xl md:text-4xl lg:text-6xl">Page Not Found</h1>
      <div className="mt-4">
        <Link to={"/"} className="underline">Back to Home</Link>
      </div>
    </motion.div>
  );
}

export default NotFound;
