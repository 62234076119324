import { Button } from "@/src/components/ui/button";
import { Input } from "@/src/components/ui/input";
import { Textarea } from "@/src/components/ui/textarea";
import { MapPinIcon } from "lucide-react";
import { toast } from "react-toastify";

function ContactPage() {
  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    try {
      e.preventDefault();
      const target: any = e.target;
      if (!target.name.value || !target.email.value) {
        toast.error("Please fill out all required fields");
        return;
      }
      const data = {
        name: target.name.value,
        email: target.email.value,
        message: target.message.value,
      };
      const response = await fetch(
        "https://rmgmrf2nrr5jxquanwgto7b2t40djmuy.lambda-url.ap-northeast-1.on.aws/",
        {
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
          },
          mode: "cors",
          method: "POST",
        }
      );
      if (response.ok) {
        toast("Message sent", { type: "success" });
      } else {
        toast("Failed to send message", { type: "error" });
      }
    } catch (e: any) {
      toast("Failed to send message", { type: "error" });
    }
  }

  return (
    <div className="full-screen py-24 flex items-center justify-center">
      <div className="w-[80%] md:w-[70%] lg:w-[60%] xl:w-[50%] flex flex-col items-center justify-center gap-2">
        <h1 className="text-2xl lg:text-3xl">Contact Us</h1>
        <div className="flex gap-2 text-black">
          <MapPinIcon />
          Unit 7, 1/F, 7 Sheung Hei Street, Kowloon, Hong Kong
        </div>
        <hr className="w-full" />
        <form
          className="flex flex-col gap-4 w-[100%]"
          onSubmit={(e) => handleSubmit(e)}
        >
          <h2 className="text-lg lg:text-xl">Leave a Message</h2>
          <Input
            type="text"
            name="name"
            maxLength={100}
            placeholder="Your Preferred Name*"
            required
          />
          <Input
            type="email"
            name="email"
            maxLength={100}
            placeholder="Email*"
            required
          />
          <Textarea name="message" maxLength={1000} placeholder="Message" />
          <Button type="submit" variant={"default"} className="w-24 h-24 text-lg lg:text-xl bg-foreground hover:bg-teal-600 mx-auto">Send</Button>
        </form>
        <hr className="w-full" />
      </div>
    </div>
  );
}

export default ContactPage;
