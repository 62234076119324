import { useEffect, useRef, useState } from "react";
import MovingBackgroundDiv from "@/src/components/ui/movingBackgroundDiv";
import { cn } from "../utils/utils";
import Footer from "../components/footer";
import DownArrowDiv from "../components/ui/downArrow";

const imageAreas = [
  {
    title: "MINDS empowering future.",
    image: "images/cover.jpg",
  },
]

function HomePage(props: { yScroll?: number; yScrollProgress?: number }) {
  const texts = [
    "Curious",
    "Innovative",
    "Collaborative",
    "Dynamic",
    "Problem-Solving",
  ];
  const pointer = useRef([0, 1, 1]); // 0: texts[0], 1: texts[0][1], 1: Direction
  const containerRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLDivElement>(null);
  const secondRef = useRef<HTMLDivElement>(null);
  const [text, setText] = useState("");
  const [blink, setBlink] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      pointer.current[1] += pointer.current[2];
      if (pointer.current[1] > texts[pointer.current[0]].length + 6) {
        pointer.current[2] = -1;
      }
      if (pointer.current[1] < 0) {
        pointer.current[2] = 1;
        pointer.current[0] += 1;
      }
      if (pointer.current[0] >= texts.length) {
        pointer.current[0] = 0;
      }
      if (pointer.current[1] === texts[pointer.current[0]].length) {
        setBlink(!blink);
      }
      setText(texts[pointer.current[0]].substring(0, pointer.current[1] + 1));
    }, 100);
    return () => clearInterval(interval);
  });

  return (
    <div>
      <div className="h-dvh relative flex justify-center items-center">
        <video
          className="absolute w-full h-dvh object-cover bg-clip-text bg-gradient-to-r from-accent-alt to-accent-foreground"
          autoPlay
          muted
          loop
        >
          <source src="1.mp4" type="video/mp4" />
        </video>
        <h1 className="text-center text-white bg-transparent p-3 md:p-5 text-4xl md:text-6xl lg:text-8xl font-semibold font-sans z-10">
          <span>{text}</span>
          <span className={cn("font-normal", blink ? "animate-blink" : "")}>
            |
          </span>
          <span className="ml-2 md:ml-4 lg:ml-6">MINDS</span>
        </h1>
        <div className="absolute bottom-4 left-4 text-white">Tons of Minds is a collective that unleashes creativity and nurtures innovative minds.</div>
        <DownArrowDiv
          className={
            cn("absolute bottom-10 left-[calc(50%-16px)] animate-bounce duration-500 transition-opacity", props.yScroll && props.yScroll > 0 ? "opacity-0" : "")
          }
          scrollRef={secondRef}
        />
      </div>

      <div className="py-16 px-8" ref={secondRef}>
        <div className="flex flex-col md:flex-row justify-center">
          <div className="md:w-4/5 lg:w-7/12 max-w-[800px]">
            <div className="mb-4" ref={textRef}>
              <h2 className="text-2xl">
                Innovation + Technology
              </h2>
              <div>Tons of Minds believes the power of innovation and the emotional value it brings to the human, we express our innovation through public artworks, metaverse, digital spaces, with technology as one of the driver. We believes the future is the era of innovation and passion.</div>
            </div>
            <img src="/images/sculpture-l.jpg" alt="sculpture" className="rounded-lg" />
            <div className="text-sm mt-2">The city installation “Flow” demonstrated the potential of AR augmented fabrication.</div>
            <div className="text-gray-700 text-sm">© Digital Lab, Faculty of Architecture | Huaqiao University</div>
          </div>
          <div className="md:pl-4 pt-4 md:pt-0 max-w-80">
            <div className="hidden md:block" style={{ height: textRef.current?.clientHeight ? textRef.current?.clientHeight + 16 : 0 }} />
            <img src="/images/sculpture-r.jpg" alt="sculpture" className="w-full max-h-[400] max-w-80 rounded-lg" />
            <div className="text-sm mt-2">We collaborate with public institutes and creatives to create public artworks.</div>
            <div className="text-gray-700 text-sm">© Joe Wong | Tons of Minds</div>
          </div>
        </div>
      </div>

      <div
        ref={containerRef}
        className="font-semibold [&>div]:bg-fixed [&>div]:bg-cover [&>div]:bg-no-repeat [&>div]:full-screen [&>div]:text-2xl [&>div]:md:text-4xl [&>div]:lg:text-6xl [&>div]:flex [&>div]:items-center [&>div]:justify-center"
      >
        {imageAreas.map(
          (area, index) => {
            return (
              <MovingBackgroundDiv
                scroll={props.yScroll || 0}
                factor={0.5}
                style={{
                  backgroundImage: `url(${area.image})`,
                }}
                key={`area-${index}`}
              >
                <span className="text-shadow text-white text-center text-xl md:text-3xl lg:text-6xl">{area.title}</span>
              </MovingBackgroundDiv>
            );
          }
        )}
      </div>
      <Footer />
    </div>
  );
}

export default HomePage;
