import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    Carousel,
    CarouselContent,
    CarouselItem,
    CarouselNext,
    CarouselPrevious,
} from "@/src/components/ui/carousel"

type ProjectDetails = {
    title: string;
    description: string;
    highlight: string;
    images: string[];
}

const ProjectPage = () => {
    const { id } = useParams();
    const [project, setProject] = useState<ProjectDetails | null>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const loadProject = async () => {
            try {
                setLoading(true);
                const projectData = await import(`../../../data/projects/${id}.json`);
                setProject(projectData);
            } catch (error) {
                console.log('Error loading project:', error);
            } finally {
                setLoading(false);
            }
        };

        loadProject();
    }, [id]);

    if (loading) {
        return <div className="mt-24 px-8">Loading...</div>;
    }

    if (!project) {
        return <div className="mt-24 px-8">Project not found</div>;
    }

    return (
        <div>
            <div className="h-dvh relative flex justify-center items-center">
                <img src={project.highlight} alt={project.title} className='h-full w-full object-cover' />
            </div>
            <h1 className='sticky bottom-8 md:bottom-16 mx-4 md:mx-16 mt-4 md:mt-8 text-3xl md:text-4xl lg:text-5xl'>{project.title}</h1>
            <div className="px-4 md:px-16 py-8 md:py-12">
                <p>{project.description}</p>
                <div>
                    <Carousel className="w-full max-w-xl md:max-w-2xl lg:max-w-3xl">
                        <CarouselContent>
                            {project.images.map((image, index) => (
                                <CarouselItem key={index}>
                                    <img src={image} alt={project.title} className="w-full h-full object-cover object-center aspect-[4/3]" />
                                </CarouselItem>
                            ))}
                        </CarouselContent>
                        <div className="flex justify-end">
                            <CarouselPrevious />
                            <CarouselNext />
                        </div>
                    </Carousel>
                </div>
            </div>
        </div>
    );
};

export default ProjectPage;

