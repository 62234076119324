import { Link } from "react-router-dom";

const projects = [
  {
    title: "Digital Transformation",
    id: '9011',
  },
  {
    title: "XiongAn Residential",
    id: '9012',
  },
  {
    title: "Renotrust",
    id: '1000',
  },
  {
    title: "Fabrication",
    id: '9001',
  },
  {
    title: "Stem Education",
    id: '3001',
  },
  {
    title: "Fabrication",
    id: '9006',
  },
  {
    title: "City of Ripples",
    id: '9014',
  },
  {
    title: "Belts of Sustainability",
    id: '9016',
  },
  {
    title: "Journey of the Woods",
    id: '9017',
  },
  {
    title: "Virtual Home",
    id: '9018',
  },
  {
    title: "Ripples and Stones",
    id: '9019',
  },
  {
    title: "Village Centre",
    id: '9020',
  },
  {
    title: "Conservation Centre",
    id: '9021',
  },
  {
    title: "Future Transport",
    id: '9022',
  },
  {
    title: "Scuplture",
    id: '9023',
  },
  {
    title: "Diaolou Conservation",
    id: '9030',
  },
  {
    title: "Paper Boat",
    id: '9002',
  },
  {
    title: "Installation Art",
    id: '9032',
  },
  {
    title: "Installation Art",
    id: '9031',
  },
  {
    title: "Small House",
    id: '9034',
  },
  {
    title: "Planning",
    id: '9033',
  },
  {
    title: "Fititure",
    id: '1001',
  },
  {
    title: "AzurPet",
    id: '1002',
  },
  {
    title: "Silverse",
    id: '1003',
  },
  {
    title: "Happy Nuts",
    id: '1005',
  },
  {
    title: "Green Transportation Kai Tak",
    id: '2001',
  },
  {
    title: "Community Architects Initiative",
    id: '2002',
  },
]

function ProjectsPage() {
  return (
    <div>
      <div className="mt-24 px-8">
        <div className="container mx-auto">
          <h1 className="text-2xl text-black font-medium mb-4">PROJECTS</h1>
          <div className="mt-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2">
            {projects.map((area, index) => {
              return (
                <Link
                  to={`/project/${area.id}`}
                  key={`service-${index}`}
                  className="rounded p-4 max-h-[480px] aspect-square bg-cover bg-no-repeat bg-center relative group"
                  style={{ backgroundImage: `url('/images/projects/${area.id}Hero1.jpg')` }}
                >
                  <div
                    className="absolute inset-0 bg-cover bg-no-repeat bg-center opacity-0 group-hover:opacity-100 transition-opacity duration-500"
                    style={{ backgroundImage: `url('/images/projects/${area.id}Hero2.jpg')` }}
                  />
                  <div className="text-xl text-white mix-blend-difference relative z-10">
                    {area.title}
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectsPage;

