"use client"

import { Link } from "react-router-dom";

function SustainabilityPage(props: {
  yScroll?: number;
}) {
  return (
    <div className="mt-24 px-8">
      <div className="block md:flex md:justify-center gap-12 mb-4">
        <h1 className="text-2xl text-black font-medium md:w-1/2 lg:w-5/12 xl:w-1/3 h-auto ">SUSTAINABILITY</h1>
        <div className="md:w-5/12 lg:w-1/4 h-fit" />
      </div>
      <div className="block md:flex md:justify-center gap-12">
        <img src="/images/sustainability/SUS1.jpg" alt="sustainability" className="md:w-1/2 lg:w-5/12 xl:w-1/3 h-auto object-contain rounded-lg" />
        <Link className="md:w-5/12 lg:w-1/4 h-fit" to={"/project/9033"}>
          <h2 className="mt-4 text-lg">
            DIGITAL SUSTAINABILITY
          </h2>
          <div className="mt-4 max-w-[500px] text-sm">
            Our digital transformation solutions are designed with sustainability at their core.
            By helping businesses transition to paperless operations and cloud-based systems, we significantly reduce physical resource consumption.
            Our data centers and digital infrastructure utilize energy-efficient technologies, supported by renewable energy sources where possible.
            This approach extends to our metaverse and digital art platforms,
            which are optimized for minimal energy consumption while delivering immersive experiences.
          </div>
        </Link>
      </div>
      <div className="block md:flex md:justify-center gap-12 mt-8">
        <img src="/images/sustainability/SUS2.jpg" alt="sustainability" className="md:w-1/2 lg:w-5/12 xl:w-1/3 h-auto object-contain rounded-lg" />
        <Link className="md:w-5/12 lg:w-1/4 h-fit" to={"/project/9017"}>
          <h2 className="mt-4 text-lg">
            CREATIVE SUSTAINABILITY
          </h2>
          <div className="mt-4 max-w-[500px] text-sm">
            In our creative sphere, we carefully select sustainable materials for our sculptural works and physical installations.
            Our digital art creation process employs energy-efficient hardware and software solutions,
            while our metaverse developments are built on platforms that prioritize low energy consumption.
            We've implemented a comprehensive recycling program for our artistic materials,
            ensuring that creative waste is minimized and repurposed whenever possible.
          </div>
        </Link>
      </div>
      <div className="block md:flex md:justify-center gap-12 mt-8">
        <img src="/images/sustainability/SUS3.jpg" alt="sustainability" className="md:w-1/2 lg:w-5/12 xl:w-1/3 h-auto object-contain rounded-lg" />
        <Link className="md:w-5/12 lg:w-1/4 h-fit" to={"/project/9032"}>
          <h2 className="mt-4 text-lg">
            WELLNESS TECHNOLOGY
          </h2>
          <div className="mt-4 max-w-[500px] text-sm">
            The intersection of technology and wellness in our operations focuses on sustainable solutions that benefit both people and planet.
            Our mental health and wellness applications are hosted on green servers,
            while our health technology products are designed with recyclable materials and energy-efficient components.
            Remote health monitoring capabilities reduce unnecessary travel,
            contributing to lower carbon emissions while maintaining high-quality care delivery.
          </div>
        </Link>
      </div>
    </div>
  );
}

export default SustainabilityPage;
